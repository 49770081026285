// app/javascript/controllers/customer_volumes_chart_controller.js
import { Controller } from '@hotwired/stimulus';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default class extends Controller {
  static targets = ['chart', 'timeRange'];

  connect() {
    this.chart = null;
    this.fetchData();
  }

  fetchData() {
    const timeRange = this.timeRangeTarget.value;
    fetch(
      `/internal/api/v1/time_series/customer_volumes?time_range=${timeRange}`,
    )
      .then((response) => response.json())
      .then((data) => this.updateChart(data));
  }

  updateChart(data) {
    if (this.chart) {
      this.chart.destroy();
    }

    const ctx = this.chartTarget.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data.map((d) => d.date),
        datasets: [
          {
            label: 'Grants',
            data: data.map((d) => d.grants_count),
            backgroundColor: '#4db6ac',
          },
          {
            label: 'Revokes',
            data: data.map((d) => d.revokes_count),
            backgroundColor: '#80cbc4',
          },
          {
            label: 'Requests',
            data: data.map((d) => d.requests_count),
            backgroundColor: '#26a69a',
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    });
  }

  changeTimeRange() {
    this.fetchData();
  }
}
