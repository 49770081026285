import { Controller } from '@hotwired/stimulus';

// Manage the Service Registry for an Organisation

import { PULSE_API_ROOT, getMetaValue } from './constants';

export default class extends Controller {
  connect() {}

  checkBoxChanged(event) {
    const organisationId = event.target.dataset.organisationId;
    const grantorId = event.target.dataset.grantorId;
    const service = event.target.dataset.service;
    const isChecked = event.target.checked;

    fetch(`${PULSE_API_ROOT}/grantors/${grantorId}/service_registries`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': getMetaValue('csrf-token'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        service,
        external_organisation_id: organisationId,
        checked: isChecked,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // console.log('Response: ', response);
          // console.log('Successfully updated!');
        } else {
          throw new Error('Failed to update.');
        }
      })
      .catch((error) => console.log(error));
  }
}
