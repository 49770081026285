import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['option', 'title'];

  static values = {
    border: { type: String, default: 'var(--hex-input-border)' },
  };

  connect() {
    if (this.hasTitleTarget) {
      this.initialText = $(this.titleTarget).text();
      this.handleSelectedOption();
      $(this.optionTargets).on('change', this.handleSelectedOption.bind(this));

      this.form = $(this.element)
        .closest('form')
        .on('reset', this.resetTitle.bind(this));
    }
  }

  resetTitle() {
    $(this.titleTarget).text(this.initialText);
  }

  handleSelectedOption(e) {
    const selected = $(this.optionTargets).filter(':checked').length;

    if (selected < 1) {
      this.resetTitle();
    } else {
      let text = $(this.optionTargets).filter(':checked').first().data('label');

      if (selected - 1 !== 0) {
        text += `, +(${selected - 1})`;
      }

      $(this.titleTarget).text(text);
    }
  }
}
