import { Controller } from '@hotwired/stimulus';
import { humanize } from '../utils/string';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['container'];

  static values = {
    preview: { type: Array, default: [] },
  };

  createPreview() {
    const form = this.element.closest('form');

    if (!form) return;

    const formData = new FormData(form);

    this.containerTargets.forEach((container, index) => {
      Object.entries(this.previewValue[index]).forEach((fieldConfig) => {
        const config = fieldConfig[1];
        const field = fieldConfig[0];

        let value = formData.get(field) || '-';

        const fieldElement = $(container).find(`div[data-field="${field}"]`);

        if (value instanceof File) {
          value = value.name || '-';
        }

        if (config.condition && value === config.condition.value) {
          value = formData.get(config.condition.field) || value;
        } else if (config.locale) {
          const locale = JSON.parse(config.locale);
          value = locale[value] || value;
        } else if (config.humanize) {
          value = humanize(value);
        }

        // Check if the field is already in the preview
        if (fieldElement.length > 0) {
          // If the field is in the preview - update it

          fieldElement.text(value);
        } else {
          // If the field is not in the preview - create it
          const labelContainer = $(
            '<div class="flex items-center text-nowrap"></div>',
          );
          const valueContainer = $(
            `<div class="flex items-center" data-field="${field}"></div>`,
          );

          labelContainer.text(config.label);
          valueContainer.text(value);

          $(container).append(labelContainer);
          $(container).append(valueContainer);
        }
      });
    });
  }
}
