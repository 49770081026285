
import NewSupportRequestValidationHelper from './NewSupportRequestValidationHelper';

export default class NewGrantorSupportRequestValidationHelper extends NewSupportRequestValidationHelper {
  stepValidations(errorMessages) {
    // Call the parent class's stepValidations method
    const parentClassResult = super.stepValidations(errorMessages);

    // Modify the first step by filtering out '#data_subject_search'
    parentClassResult[0] = parentClassResult[0].filter(
      (validation) => validation.id !== '#data_subject_search',
    );

    parentClassResult[0].push({
      type: 'field',
      id: '#grantor_id',
      rules: [
        {
          rule: 'required',
          errorMessage: errorMessages['data_subject_search'],
        },
      ],
      config: {
        errorsContainer: '#errors-container_data_subject_search',
      },
    });

    // Return the modified validation steps
    return parentClassResult;
  }
}
