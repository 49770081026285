import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['option', 'selectedCount'];

  connect() {
    $(this.optionTargets).on('change', this.handleOptionChange.bind(this));

    $(this.optionTarget).closest('form').on('reset', this.formReset.bind(this));
  }

  formReset() {
    $(this.selectedCountTarget).hide();
    $(this.selectedCountTarget).text('');

    $(this.optionTargets).filter('[type="radio"]').attr('checked', false);
  }

  handleOptionChange(e) {
    const selected = $(this.optionTargets).filter(':checked').length;

    if (selected > 0) {
      if ($(this.selectedCountTarget).hasClass('hidden'))
        $(this.selectedCountTarget).css('display', 'flex');
      $(this.selectedCountTarget).text(`${selected}`);
    } else {
      $(this.selectedCountTarget).hide();
      $(this.selectedCountTarget).text('');
    }
  }
}
