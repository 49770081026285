import TimeSeriesChartsController from './time_series_charts_controller.js';
// Required data for stacked bar chart

// title - chart title

// traces (for stacked bar chart) - array

// xAxis - array of string/number/date to be displayed on the x-axis
// e.g. ["Product1", "Product2", "Product3"]

// yAxis - array of number or array of Hashes if traces provided
// e.g. ["Location1", "Location2"] or
// [{ trace1: value, trace2: value }, { trace1: value, trace2: value }]

export default class extends TimeSeriesChartsController {
  static targets = ['container', 'tooltip', 'legend'];

  static values = {
    yAxis: { type: Array, default: [] },
    xAxis: { type: Array, default: [] },
    traces: { type: Array, default: [] },
    options: { type: Object, default: {} },
    colorSet: { type: Array, default: [] },
    dateRangeTick: { type: String, default: '1 month' },
    timePeriod: { type: String, default: '12M' },
  };

  connect() {
    super.connect();

    const traceTemplate = {
      type: 'bar',
      orientation: this.orientation,
      visible: true,
      marker: {
        color: this.colorSetValue[0] || this.defaultColorSet[0],
      },
      hoverinfo: 'none',
      x: this.xAxis,
      margin: { t: 0, b: 0, r: 0, l: 0, pad: 0 },
    };

    let data = [];

    if (this.hasTraces) {
      data = this.tracesValue.map((trace, index) => {
        return {
          ...traceTemplate,
          name: this.geTraceName(trace),
          initialName: trace,
          marker: {
            color: this.colorSetValue[index] || null,
          },
          width: this.barWidth,
          y: this.yAxis.map((i) => i[trace]),
        };
      });
    } else {
      data = [
        {
          ...traceTemplate,
          y: this.yAxis,
          width: this.barWidth,
        },
      ];
    }

    if (!this.yAxisValue.length || !this.xAxisValue.length) {
      this.drawEmptyResult(this.defaultTimeSeriesLayoutConfig, data);

      return;
    }

    this.drawChart(data, this.defaultTimeSeriesLayoutConfig);
  }

  get barWidth() {
    let width = 86400000 * 10;

    switch (this.timePeriodValue) {
      case '1W': {
        width = 86400000 * 0.8;
        break;
      }
      case '3M': {
        width = 86400000 * 2;
        break;
      }
      case '6M': {
        width = 86400000 * 4;
        break;
      }
      case '12M': {
        width = 86400000 * 10;
        break;
      }
    }

    return width;
  }
}
