import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['options', 'title', 'flag', 'flagIcon', 'icon'];

  connect() {
    $(this.optionsTarget)
      .find('input')
      .on('click', this.updateSelectorTitle.bind(this));
  }

  updateSelectorTitle() {
    const selected = $(this.optionsTarget)
      .find('input[type="radio"]:checked')
      .first();

    if (selected) {
      const value = selected.val();

      if (this.hasFlagIconTarget) $(this.flagIconTarget).addClass('hidden');

      $(this.flagTarget)
        .removeClass('hidden')
        .attr('src', `https://flagcdn.com/${value.toLocaleLowerCase()}.svg`);
      $(this.titleTarget).text($(selected).next('label').text());
      $(this.optionsTarget).delay(5).slideFadeToggle(100);
      $(this.iconTarget).removeClass('rotate-180');
    }
  }
}
