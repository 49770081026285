// Seems that Plotly doesn't allow to set colours via CSS variables
// TODO: find a solution to maintain both dark/light themes
export const COLORS = {
  line: '#9DA3AE',
  grid: '#E5E7EB',
  tick: '#121826',
  axis_title: '#121826',
  chart_bg: 'transparent',
  legend: {
    inactive: {
      text: '#ffffff87',
      bg: '#82828438',
    },
    active: {
      text: '#ffffff',
    },
  },
  annotation: '#FFFFFFCC',
};

export const STYLES = {
  axis: {
    label: {
      font: 'Inter',
      default_size: 14,
      small_size: 10,
    },
    title: {
      font: 'Poppins',
      default_size: 16,
      small_size: 14,
      standoff: 32,
    },
  },
  annotations: {
    family: 'Inter',
    size: 18,
  },
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_BY_MONTHS = 'YYYY-MM';

export const DATE_RANGE_TICK = {
  month: 'M1',
  week: 86400000 * 7,
  day: 86400000,
};

export const MAX_DAYS_IN_MONTH = 31;
export const MISSING_DATA_MESSAGE = 'No Data';

export const DEFAULT_ORIENTATION = 'v';
export const DEFAULT_COLOR_SET = [
  '#63538D',
  '#965692',
  '#C55986',
  '#E7686E',
  '#EE8251',
  '#F2A93C',
  '#F4E773',
  '#a4e74fe0',
  '#1BD67C',
  '#50D7CF',
  '#4FA1ED',
  '#496FF8',
];

export function humanizeString(str) {
  return str
    .replace(/(^\w)/g, (g) => g[0].toUpperCase())
    .replace(/([-_]\w)/g, (g) => ' ' + g[1].toUpperCase())
    .trim();
}

export function getDateTick(dateRange) {
  switch (dateRange) {
    case '1 month':
      return DATE_RANGE_TICK.month;
    case '1 week':
      return DATE_RANGE_TICK.week;
    case '2 week':
      return 2 * DATE_RANGE_TICK.week;
    case '1 day':
      return DATE_RANGE_TICK.day;
    case '2 day':
      return 2 * DATE_RANGE_TICK.day;
    case '4 day':
      return 4 * DATE_RANGE_TICK.day;
    default:
      return DATE_RANGE_TICK.month;
  }
}

export const europeCountryNames = [
  'Albania',
  'Latvia',
  'Andorra',
  'Liechtenstein',
  'Armenia',
  'Lithuania',
  'Austria',
  'Luxembourg',
  'Azerbaijan',
  'Malta',
  'Belarus',
  'Moldova',
  'Belgium',
  'Monaco',
  'Bosnia and Herzegovina',
  'Montenegro',
  'Bulgaria',
  'Netherlands',
  'Croatia',
  'Norway',
  'Cyprus',
  'Poland',
  'Czech',
  'Portugal',
  'Denmark',
  'Romania',
  'Estonia',
  'Russia',
  'Finland',
  'San Marino',
  'Macedonia',
  'Serbia',
  'France',
  'Slovakia',
  'Georgia',
  'Slovenia',
  'Germany',
  'Spain',
  'Greece',
  'Sweden',
  'Hungary',
  'Sweden',
  'Iceland',
  'Switzerland',
  'Ireland',
  'Turkey',
  'Italy',
  'Ukraine',
  'Kosovo',
  'United Kingdom',
];

export const europeanUnionNames = [
  'Austria',
  'Latvia',
  'Luxembourg',
  'Lithuania',
  'Malta',
  'Belgium',
  'Bulgaria',
  'Netherlands',
  'Cyprus',
  'Poland',
  'Czech',
  'Portugal',
  'Denmark',
  'Romania',
  'Estonia',
  'Finland',
  'France',
  'Slovakia',
  'Slovenia',
  'Germany',
  'Spain',
  'Greece',
  'Sweden',
  'Croatia',
  'Sweden',
  'Ireland',
  'Italy',
  'Hungary',
];
