import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['welcomeBlock', 'setPasswordForm'];

  showForm() {
    $(this.welcomeBlockTarget).hide();
    $(this.setPasswordFormTarget).show();
  }
}
