import $ from 'jquery';

import { Controller } from '@hotwired/stimulus';
import { buildURL, turboFetchRequest, turboPostRequest } from '../../src/helpers/requests';

const ROOT_PATH = "/admin/cards";

export default class extends Controller {
  static targets = [
    'form',
    'chartType',
    'categories',
    'visualisations',
    'visualisationHeader',
    'visualisationDescription',
    'navigationSetting',
  ];

  

  connect() {
    $('#action-buttons-section').hide();
    $('[data-wizard-form-target="prev"]').hide();


    // $(this.formTarget).on('submit', function(event) {      
    //   const navigationFields = $(self.navigationSettingTargets).filter(`[data-option-value!='${self.navigationOptionValue}']`).find('input');
  
    //   // Find and disable only the invisible fields
    //   $(navigationFields).each(function() {
    //     $(this).prop('disabled', true);
    //   });
    // });

    this.boundHandleWizardFormStepChanged = this.handleWizardFormStepChanged.bind(this);

    document.addEventListener('wizard-form-step-changed', this.boundHandleWizardFormStepChanged);
  }

  disconnect() {
    document.removeEventListener('wizard-form-step-changed', this.boundHandleWizardFormStepChanged);
  }

  onChooseChartCategory(e) {
    const value = e.target.value;

    $(this.categoriesTarget).hide();
    $(this.visualisationsTarget).show();

    $(this.visualisationHeaderTarget).text($(e.target).data('header'));
    $(this.visualisationDescriptionTarget).text($(e.target).data('description'));

    $(this.chartTypeTargets).filter(`[data-chart-type-value='${value}']`).show();
    $(this.chartTypeTargets).filter(`[data-chart-type-value!='${value}']`).hide();
  }

  refreshActiveRecortOptions(event) {
    const selectedCardCategory = event.target.value;
    const target = event.params.target;

    const params = {
      card_category: selectedCardCategory,
      target,
    };

    const url = buildURL('active_records', params, ROOT_PATH);

    turboFetchRequest(
      url,
      'Something went wrong - failed to fetch new active record options.',
    );
  }

  refreshCalculationsOptions(event) {
    const target = event.params.target;
    const selectedActiveRecord = event.target.value;

    const params = {
      target,
      active_record: selectedActiveRecord,
    };

    const url = buildURL('calculations', params, ROOT_PATH);

    turboFetchRequest(
      url,
      'Something went wrong - failed to fetch new calculation options.',
    );
  }

  getChartConfigFields(event) {
    const target = event.params.configTarget;
    const calculationKlass = event.target.value;
    let visualisation = $('input[name="card[insight[visualisation]]"]').filter(':checked').val();

    if (visualisation === 'turbo_stream') {
      visualisation = $('input[name="card[insight[turbo_stream_type]]"]').filter(':checked').val();
    }

    const params = {
      visualisation,
      target,
      calculation_klass: calculationKlass,
    };

    const url = buildURL('chart_configuration', params, ROOT_PATH);

    turboFetchRequest(
      url,
      'Something went wrong - failed to fetch chart config',
    );
  }

  getNestedFormData(formElement) {
    const formData = new FormData(formElement);
    const data = {};
  
    formData.forEach((value, key) => {
      // Split the keys by the brackets to identify nesting (e.g., 'card[options][visual]')
      const keys = key.split(/\[|\]/).filter(k => k); // Filter out empty strings
      let currentLevel = data;
  
      // Iterate over the keys to build nested objects
      keys.forEach((k, index) => {
        if (index === keys.length - 1) {
          // Final key, assign the value
          currentLevel[k] = value;
        } else {
          // Create a nested object if it doesn't exist
          if (!currentLevel[k]) {
            currentLevel[k] = {};
          }
          currentLevel = currentLevel[k];
        }
      });
    });
  
    return data;
  }

  generatePreview(target = 'chart_preview_section') {
    const formData = this.getNestedFormData(this.formTarget);

    formData['target'] = target;

    const url = buildURL('preview', null, ROOT_PATH);

    turboPostRequest(url, formData, 'Something went wrong - failed to generate chart preview', null);
  }

  backToCategories() {
    $(this.categoriesTarget).find('input[type="radio"]').prop('checked', false);

    $(this.chartTypeTargets).hide();
    $(this.categoriesTarget).show();
    $(this.visualisationsTarget).hide();
  }

  onChooseChartVisualisation(e) {
    const value = e.target.value;

    this.visualisationValue = value;

    $('#action-buttons-section').show();

    $('[data-wizard-form-target="next"]').trigger('click');
  }

  getCalculationExampleResult(e) {
    this.calculationClassValue = e.target.value;
    const id = e.params.target;

    fetch(
      `/calculations/example_result.json?calculation_klass=${this.calculationClassValue}`,
    )
      .then((response) => response.json())
      .then(res => {
        $(`#${id}`).val(res['example_result']);
      });
  }

  handleWizardFormStepChanged(e) {
    const step = e.detail.step;

    if (step === 4) {
      this.generatePreview();
    }
  }

}
