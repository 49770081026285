import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['question', 'summary'];

  connect() {
    this.element.classList.add('opacity-0');
    setTimeout(() => {
      this.element.classList.remove('opacity-0');
      this.element.classList.add('opacity-100');
    }, 50);
  }

  selectAnswer(event) {
    this.element.classList.remove('opacity-100');
    this.element.classList.add('opacity-0');
  }

  updateSummary(event) {
    const [data, status, xhr] = event.detail;
    if (this.hasSummaryTarget) {
      this.summaryTarget.innerHTML =
        xhr.response.querySelector('#summary').innerHTML;
    }
  }
}
