import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [
    'root',
    'name',
    'id',
    'email',
    'country',
    'hiddenFormField',
  ];

  reset() {
    $(this.hiddenFormFieldTargets).each(function () {
      $(this).attr('value', null);
    });
  }

  show(user) {
    $(this.rootTarget).css('display', 'grid');
    $(this.nameTarget).html(`${user.first_name} ${user.last_name}`);
    $(this.idTarget).html(`${user.id}`);
    $(this.emailTarget).html(`${user.email}`);
    $(this.countryTarget).html(`${user.country}`);

    $(this.hiddenFormFieldTargets).each(function () {
      if ($(this).data('key')) {
        const key = $(this).data('key');

        let value = user[key];

        if (key.includes(' ')) {
          value = key
            .split(' ')
            .reduce((res, key) => {
              res.push(user[key]);

              return res;
            }, [])
            .join(' ')
            .trim();
        }

        $(this).attr('value', value);
        $(this).trigger('input');
      }
    });
  }

  hide() {
    $(this.rootTarget).css('display', 'none');
  }
}
