import ValidationHelper from './validation_helper';

export default class NewPermissionValidationHelper extends ValidationHelper {
  stepValidations(errorMessages) {
    return [
      [
        {
          type: 'field',
          id: '#permission_short_description',
          rules: [
            {
              rule: 'required',
              errorMessage: errorMessages['identifier'],
            },
          ],
        },
        {
          type: 'field',
          id: '#permission_description',
          rules: [
            {
              rule: 'required',
              errorMessage: errorMessages['description'],
            },
          ],
        },
      ],
    ];
  }
}
