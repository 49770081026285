import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['arrow', 'collapsableColumn'];
  static classes = ['arrowOpen'];

  connect() {
    const self = this;

    if (this.hasCollapsableColumnTarget) {
      $(this.collapsableColumnTargets).each(function () {
        const index = $(this).parent().data('index');

        const arrow = $(self.arrowTargets).filter(`[data-target="${index}"]`);

        if (this.offsetWidth < this.scrollWidth) {
          $(arrow).show();
          $(arrow).on('click', self.toggleRow.bind(self));
        } else {
          $(arrow).hide();
        }
      });
    } else {
      $(this.arrowTargets).on('click', this.toggleRow.bind(this));
    }
  }

  toggleRow(e) {
    const index = $(e.currentTarget).data('target');
    const row = $(e.currentTarget).closest('tr');

    if (this.hasCollapsableColumnTarget) {
      $(row).delay(5).toggleClass('expanded');
    } else {
      $(this.element)
        .find(`tr[data-target="${index}"]`)
        .delay(5)
        .toggleClass('hidden');
    }

    if (this.hasArrowOpenClass) {
      $(e.currentTarget).toggleClass(this.arrowOpenClass);
    }
  }
}
