import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import { highlightSection, splitByWhitespace } from '../utils/string';
import { PULSE_API_ROOT } from './constants';

const ITEMS_PER_PAGE = 40;

export default class extends Controller {
  static targets = [
    'input',
    'table',
    'container',
    'trigger',
    'spinner',
    'value',
    'close',
  ];

  static values = {
    navigation: { type: Boolean, default: false },
  };

  static outlets = ['selected-subject'];

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this));
    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
    ).observe(this.triggerTarget);
    this.nextPage = 1;
    this.loading = false;
  }

  unobserve() {
    this.observer.unobserve(this.triggerTarget);
  }

  handleIntersection(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadOptions();
      }
    });
  }

  handleClickOutside(e) {
    if (!this.element.contains(e.target)) {
      $(this.tableTarget).hide();
      $(this.closeTarget).hide();
    }
  }

  handleFocus(e) {
    if (e.target.value.length > 0) {
      $(this.closeTarget).show();
    }

    $(this.tableTarget).show();

    if (this.isQueryEmpty) {
      this.loadOptions();
    }
  }

  onInput(e) {
    const self = this;

    if (e.target.value.length > 0) {
      $(this.closeTarget).show();
    } else {
      $(this.closeTarget).hide();
    }

    setTimeout(() => {
      self.doSearch(e);
    }, 300);
  }

  doSearch(event) {
    this.selectedSubjectOutlet.reset();
    this.selectedSubjectOutlet.hide();
    this.nextPage = 1;
    this.query = event.target.value;
    $(this.tableTarget).show();
    this.clearOptions();
    this.loadOptions();
  }

  get isQueryEmpty() {
    return !this.query || this.query.length === 0;
  }

  async loadOptions() {
    if (this.isLoading || !this.nextPage) {
      return;
    }

    this.isLoading = true;
    let query = `?items=${ITEMS_PER_PAGE}&page=${this.nextPage}`;

    if (!this.isQueryEmpty) {
      query += `&search=${this.query}`;
    }

    fetch(`${PULSE_API_ROOT}/grantors/search${query}`, {
      method: 'GET',
      credentials: 'same-origin',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        return response.json();
      })
      .then((data) => {
        this.isLoading = false;

        if (!data.page.next) {
          $(this.spinnerTarget).hide();
        }

        this.nextPage = data.page.next;
        this.renderOptions(data.page.grantors);
      })
      .catch((e) => {
        console.error('There was a fetching grantors list:', e);
        this.isLoading = false;
      });
  }

  clearOptions() {
    $(this.containerTarget).empty();
  }

  renderOptions(options) {
    if (options.length === 0) {
      const row = document.createElement('tr');

      $(row)
        .attr('class', 'h-8')
        .append(
          `<td colspan="5" class="text-center">No data subjects found. Please check your search term.</td>`,
        );

      $(row).appendTo(this.containerTarget);

      return;
    }

    options.forEach((option, index) => {
      const row = document.createElement('tr');

      $(row).attr(
        'class',
        'h-8 cursor-pointer border-b border-color-extended-grey-100',
      );

      // If user pastes a name, consisting of more than one word, treat them as first and last name
      const formattedName = this.formatName(option);
      const formattedEmail = this.formatEmail(option);
      const formattedId = this.formatId(option);
      $(row).append(
        `<td class="text-left whitespace-pre">${formattedName}</td>`,
      );
      $(row).append(`<td class="text-left">${formattedEmail}</td>`);
      $(row).append(`<td class="text-left">${formattedId}</td>`);
      $(row).append(`<td class="text-left">${option.country}</td>`);
      $(row).append(
        `<td class="text-right">${
          this.navigationValue
            ? '<i class="w-24 text-end fa-solid fa-arrow-right grow text-highlighted-hex"></i>'
            : ''
        }</td>`,
      );

      const self = this;

      $(row).on('click', (e) => {
        if (self.navigationValue) {
          $(this.inputTarget).val('');

          window.location.assign(`/grantors/${option.id}`);
          return;
        }
        const fullName = `${option.first_name} ${option.last_name}`;
        $(this.inputTarget).val(fullName);
        this.query = fullName;
        this.selectedSubjectOutlet.show(option);
        $(this.tableTarget).hide();
      });

      $(row).appendTo(this.containerTarget);
    });
  }

  formatName(option) {
    if (!this.query) {
      return `${option.first_name} ${option.last_name}`;
    }
    const words = splitByWhitespace(this.query);
    return words.length > 1
      ? `<span>${highlightSection(
          option.first_name,
          words[0],
        )} ${highlightSection(option.last_name, words[1])}</span>`
      : `${highlightSection(option.first_name, this.query)} ${highlightSection(
          option.last_name,
          this.query,
        )}`;
  }

  formatEmail(option) {
    if (!this.query) {
      return `${option.email}`;
    }
    return highlightSection(option.email, this.query);
  }

  formatId(option) {
    if (!this.query) {
      return `${option.id}`;
    }
    return highlightSection(option.id, this.query);
  }

  get isLoading() {
    return this.loading;
  }

  set isLoading(value) {
    this.loading = value;
    if (value) {
      $(this.spinnerTarget).show();
    }
  }

  close(event) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedSubjectOutlet.reset();
    this.selectedSubjectOutlet.hide();
    $(this.inputTarget).val('');
    this.query = undefined;
    this.nextPage = 1;
    $(this.tableTarget).hide();
    this.clearOptions();
    $(this.closeTarget).hide();
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }
}
