import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import $ from 'jquery';

export default class extends Controller {
  static targets = [
    'description',
    'message',
    'confirmButtonLabel',
    'cancelButton',
  ];

  connect() {
    Turbo.setConfirmMethod((message, element) => {
      const dialog = this.element;
      const description = element.dataset.turboConfirmDescription || '';
      const buttonLabel = element.dataset.turboConfirmButtonLabel || '';
      const showCancelButton = element.dataset.turboConfirmCancelButton;

      $(this.messageTarget).text(message);

      if (description) {
        this.descriptionTarget.innerHTML = description;
      }

      if (buttonLabel) {
        $(this.confirmButtonLabelTarget).text(buttonLabel);
      }

      if (showCancelButton === 'true') {
        $(this.cancelButtonTarget).show();
      }

      dialog.showModal();

      return new Promise((resolve) => {
        dialog.addEventListener(
          'close',
          () => {
            resolve(dialog.returnValue === 'confirm');
          },
          { once: true },
        );
      });
    });
  }
}
