import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['questionsContainer', 'newQuestionForm'];

  addNewQuestion(event) {
    event.preventDefault();
    this.newQuestionFormTarget.classList.remove('hidden');
    this.newQuestionFormTarget.scrollIntoView({ behavior: 'smooth' });
  }

  // You can add more methods here for handling form submission, updating the tree, etc.
}
