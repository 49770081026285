import { Turbo } from '@hotwired/turbo-rails';

export function buildURL(path, queryParamsHash = {}, ROOT_PATH = '') {
  const searchParams = (new URLSearchParams(queryParamsHash)).toString();

  const urlPath = ROOT_PATH.length ? `${ROOT_PATH}/${path}` : `${path}`;

  return `${urlPath}${searchParams.length ? '?' + searchParams: ''}`;
}

export function turboFetchRequest(
  url,
  errorMessage = 'Something went wrong',
  callback,
  options = {},
) {
  const defaultOptions = {
    headers: {
      'Content-Type': 'text/vnd.turbo-stream.html',
    },
  };

  fetch(url, {
    ...defaultOptions,
    ...options,
  })
    .then(function (response) {
      // The API call was successful!
      return response.text();
    })
    .then(function (html) {
      // This is the HTML from our response as a text string
      Turbo.renderStreamMessage(html);

      if (callback) {
        callback();
      }
    })
    // eslint-disable-next-line dot-notation
    .catch((response) => {
      // There was an error
      console.warn(response.status, response);
    });
}

export function turboPostRequest(
  url,
  formData = null,
  errorMessage = 'Something went wrong',
  callback,
  options = {},
) {
  const defaultOptions = {
    method: 'POST',
    headers: {
      'X-CSRF-Token': getMetaValue('csrf-token'),
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest', // To mark it as an AJAX request for Rails
    },
  };

  let body = null;

  if (formData && typeof formData === 'object') {
    // Convert the object to JSON
    body = JSON.stringify(formData);
  }

  fetch(url, {
    ...defaultOptions,
    body: body,
    ...options,
  })
    .then(function (response) {
      if (!response.ok) {
        throw new Error(response.statusText || errorMessage);
      }
      return response.text();
    })
    .then(function (html) {
      Turbo.renderStreamMessage(html);

      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      console.warn('Error:', error.message || error);
    });
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element.getAttribute('content');
}