import { Controller } from '@hotwired/stimulus';
import JustValidate from 'just-validate';

export default class extends Controller {
  static targets = ['password', 'passwordConfirmation', 'criteria'];

  connect() {
    this.validator = new JustValidate(this.element, {
      errorFieldCssClass: 'border-red-500',
    });

    this.validator
      .addField(this.passwordTarget, [
        {
          rule: 'required',
        },
        {
          rule: 'password',
          errorMessage: 'Password is invalid',
        },
      ])
      .addField(this.passwordConfirmationTarget, [
        {
          rule: 'required',
          errorMessage: 'Confirm your password',
        },
        {
          validator: (value, fields) => {
            return value === this.passwordTarget.value;
          },
          errorMessage: 'Passwords do not match',
        },
      ])
      .onSuccess((event) => {
        event.target.submit();
      });

    this.passwordTarget.addEventListener(
      'input',
      this.updateCriteria.bind(this),
    );
  }

  updateCriteria() {
    const password = this.passwordTarget.value;
    const criteria = {
      length: password.length >= 12,
      uppercase_letter: /[A-Z]/.test(password),
      lowercase_letter: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[@$!%*?&]/.test(password),
    };

    Object.keys(criteria).forEach((key) => {
      const element = this.criteriaTarget.querySelector(`.${key}`);
      if (criteria[key]) {
        element.classList.add('valid');
      } else {
        element.classList.remove('valid');
      }
    });
  }
}
