import BaseChartsController from './base_charts_controller.js';
import * as d3 from 'd3';

const CONSTANTS = {
  size: 100,
  bgThickness: 7,
  chartThickness: 12,
  backgroundFill: '#a5b4fd',
  foregroundFill: '#4338ca',
  valueLabelPaddingWithLabel: 0.7,
  valueLabelPadding: 0.9,
};

export default class extends BaseChartsController {
  static targets = ['container', 'tooltip'];

  static values = {
    value: Number,
    label: String,
  };

  get hasLabel() {
    return this.hasLabelValue && this.labelValue.length > 0;
  }

  connect() {
    const arc = d3
      .arc()
      .innerRadius(CONSTANTS.size - CONSTANTS.bgThickness)
      .outerRadius(CONSTANTS.size - CONSTANTS.bgThickness / 2)
      .cornerRadius(20)
      .startAngle(-Math.PI / 2);

    this.arcForeground = d3
      .arc()
      .innerRadius(CONSTANTS.size - CONSTANTS.chartThickness)
      .outerRadius(CONSTANTS.size)
      .cornerRadius(20)
      .startAngle(-Math.PI / 2);

    if (!d3.select(this.containerTarget).select('svg').empty()) {
      return;
    }

    const svg = d3
      .select(this.containerTarget)
      .append('svg')
      .attr('width', CONSTANTS.size * 2)
      .attr('height', CONSTANTS.size)
      .attr('class', 'gauge');

    const chart = svg
      .append('g')
      .attr(
        'transform',
        'translate(' + CONSTANTS.size + ',' + CONSTANTS.size + ')',
      );

    chart
      .append('path')
      .datum({
        endAngle: Math.PI / 2,
      })
      .style('fill', CONSTANTS.backgroundFill)
      .attr('d', arc);

    this.foreground = chart
      .append('path')
      .datum({
        endAngle: -Math.PI / 2,
      })
      .style('fill', CONSTANTS.foregroundFill)
      .attr('d', this.arcForeground);

    this.textSvg = svg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          CONSTANTS.size +
          ',' +
          CONSTANTS.size *
            (this.hasLabel
              ? CONSTANTS.valueLabelPaddingWithLabel
              : CONSTANTS.valueLabelPadding) +
          ')',
      )
      .append('text')
      .text(0)
      .attr('text-anchor', 'middle')
      .attr('class', 'font-heading-regular-h2 text-color-brand-ui-900');

    if (this.hasLabel) {
      svg
        .append('g')
        .attr(
          'transform',
          'translate(' + CONSTANTS.size + ',' + CONSTANTS.size * 0.9 + ')',
        )
        .append('text')
        .text(this.labelValue)
        .attr('text-anchor', 'middle')
        .attr('class', 'font-text-regular-xs text-color-brand-ui-700');
    }

    this.update(Math.random() * 100);
  }

  update(v) {
    const self = this;

    v = d3.format('.1f')(this.valueValue);
    this.foreground
      .transition()
      .duration(750)
      .style('fill', function () {
        return '#4338ca';
      })
      .call(this.arcTween.bind(self), v);

    this.textSvg.transition().duration(750).call(this.textTween, v);
  }

  arcTween(transition, v) {
    const self = this;

    const newAngle = (v / 100) * Math.PI - Math.PI / 2;
    transition.attrTween('d', function (d) {
      const interpolate = d3.interpolate(d.endAngle, newAngle);

      return function (t) {
        d.endAngle = interpolate(t);
        return self.arcForeground(d);
      };
    });
  }

  textTween(transition, v) {
    transition.tween('text', function () {
      const interpolate = d3.interpolate(this.innerHTML, v);
      const split = (v + '').split('.');
      const round = split.length > 1 ? Math.pow(10, split[1].length) : 1;

      return function (t) {
        this.innerHTML =
          d3.format('.0f')(Math.round(interpolate(t) * round) / round) +
          '<tspan>%</tspan>';
      };
    });
  }
}
