import ValidationHelper from './validation_helper';

export default class NewSupportRequestValidationHelper extends ValidationHelper {
  showErrors(validator) {
    const errors = Object.values(validator.fields)
      .filter((f) => f.isValid === false)
      .reduce((acc, cur) => {
        const fieldId =
          cur.elem.id === 'grantor_id' ? 'data_subject_search' : cur.elem.id;
        return { ...acc, [`#${fieldId}`]: cur.errorMessage };
      }, {});
    validator.showErrors(errors);
  }

  stepValidations(errorMessages) {
    return [
      [
        {
          type: 'field',
          id: '#data_subject_search',
          rules: [
            {
              rule: 'required',
            },
          ],
          config: {
            errorsContainer: '#errors-container_data_subject_search',
          },
        },
        {
          type: 'field',
          id: '#grantor_id',
          rules: [
            {
              rule: 'required',
              errorMessage: errorMessages['data_subject_search'],
            },
          ],
          config: {
            errorsContainer: '#errors-container_data_subject_search',
          },
        },
        {
          type: 'group',
          id: '#request_type_id_group',
          errorMessage: errorMessages['request_type_id'],
        },
        {
          type: 'group',
          id: '#request_reason_group',
          errorMessage: errorMessages['request_reason'],
        },
        {
          type: 'group',
          id: '#request_origin',
          errorMessage: errorMessages['request_origin'],
          config: {
            errorsContainer: '#errors-container_request_origin',
          },
          condition_validation: {
            contact_of_data_subject: {
              sub_fields: [
                {
                  type: 'field',
                  id: '#request_requester_name',
                  rules: [
                    {
                      rule: 'required',
                      errorMessage: errorMessages['request_requester_name'],
                    },
                  ],
                },
                {
                  type: 'field',
                  id: '#request_requester_email',
                  rules: [
                    {
                      rule: 'required',
                      errorMessage:
                        errorMessages['request_requester_email_required'],
                    },
                    {
                      rule: 'email',
                      errorMessage:
                        errorMessages['request_requester_email_valid'],
                    },
                  ],
                },
                {
                  type: 'group',
                  id: '#request_request_requester_relationship_group',
                  errorMessage: errorMessages['request_requester_relationship'],
                },
              ],
            },
          },
        },
      ],
      [
        {
          type: 'field',
          id: '#support_request_comments',
          rules: [
            {
              rule: 'required',
              errorMessage: errorMessages['support_request_comments'],
            },
          ],
        },
        {
          type: 'field',
          id: '#support_request_comments_url',
          rules: [
            {
              rule: 'customRegexp',
              // eslint-disable-next-line no-useless-escape
              value:
                '[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)',
              errorMessage: errorMessages['support_request_comments_url'],
            },
          ],
        },
      ],
    ];
  }
}
