import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'name', 'container'];

  onFileSelect(event) {
    const file = event.target.files[0];
    this.containerTarget.classList.remove('hidden');
    this.containerTarget.classList.add('flex');
    this.nameTarget.textContent = file.name;
  }

  clearFile() {
    this.inputTarget.value = null;
    this.containerTarget.classList.remove('flex');
    this.containerTarget.classList.add('hidden');
    this.nameTarget.textContent = '';
  }
}
