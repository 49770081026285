import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['savePannel', 'master', 'checkbox'];

  connect() {
    const self = this;

    this.saveInitialValues();
    this.toggleMuteCheckboxes();
    this.hideSavePannel();

    $(self.checkboxTargets)
      .filter(function () {
        return $(this).data('default');
      })
      .on('change', function () {
        $(self.masterTarget).trigger('click');
      });

    $('form :input').on('keyup change', this.onFormChanged.bind(this));

    // Checks pref options and disables the Save Changes button if no one is selected
    $(this.masterTarget).on('change', function () {
      self.toggleMuteCheckboxes(this.checked);

      const defaultCheckbox = $(self.checkboxTargets).filter(function () {
        return $(this).data('default');
      });

      if (defaultCheckbox.length && this.checked) {
        defaultCheckbox.prop('checked', true);
      }
    });

    $('input[name="update_all_accounts"]').on('change', function () {
      $(this)
        .next()
        .text(this.checked ? 'Yes' : 'No');
    });
  }

  hideSavePannel() {
    if ($(this.savePannelTarget).is(':hidden')) return;

    $(this.savePannelTarget).css({ transform: 'translate(0, 100%)' });

    setTimeout(() => {
      $(this.savePannelTarget).hide();
    }, 200);

    $(this.savePannelTarget).data('');
  }

  showSavePannel() {
    if (!$(this.savePannelTarget).is(':hidden')) return;

    $(this.savePannelTarget).show();
    $(this.savePannelTarget).css({ transform: 'translate(0, 0)' });
  }

  resetForm() {
    $('form').trigger('reset');
    this.hideSavePannel();

    this.toggleMuteCheckboxes();
  }

  checkAllOptions() {
    $(this.checkboxTargets).filter(':not(:checked)').trigger('click');
  }

  uncheckAllOptions() {
    $(this.checkboxTargets).filter(':checked').trigger('click');
  }

  toggleMuteCheckboxes(defaultMasterChecked) {
    const masterChecked =
      defaultMasterChecked || $(this.masterTarget).is(':checked');

    if (masterChecked) {
      $(this.checkboxTargets).removeAttr('disabled');
      $(this.checkboxTargets).parent('.pulse-toggle-switch').css('opacity', 1);
    } else {
      $(this.checkboxTargets).prop('checked', false).attr('disabled', true);
      $(this.checkboxTargets)
        .parent('.pulse-toggle-switch')
        .css('opacity', 0.6);
    }
  }

  // Saves an initial value of all input tags to their data-attribute to check if the form was changed
  saveInitialValues() {
    $('form :input').each(function () {
      const currentValue =
        $(this).is(':checkbox') || $(this).is(':radio')
          ? this.checked
          : this.value;

      $(this).data('value', currentValue);
    });
  }

  onFormChanged() {
    const dataChanged = $('form :input:not([type=hidden])').filter(function () {
      const currentValue =
        $(this).is(':checkbox') || $(this).is(':radio')
          ? this.checked
          : this.value;

      return $(this).data('value') !== currentValue;
    }).length;

    // Hides save panel if no one form elements were changed
    dataChanged === 0 ? this.hideSavePannel() : this.showSavePannel();
  }

  onPostSuccess() {
    this.hideSavePannel();
  }
}
