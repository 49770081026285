import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    const formId = $(this.element).attr('form');

    if (formId) {
      this.form = $(`#${formId}`);
    } else {
      this.form = $(this.element).closest('form');
    }

    this.toggleButton();

    $(this.form).on('reset', () => {
      $(this.element).prop('disabled', true);
    });
    $(this.form)
      .find(':input:not(:button):not([type=hidden])')
      .on('change', this.toggleButton.bind(this));
  }

  toggleButton() {
    $(this.element).prop('disabled', !this.isFormDirty);
  }

  get isFormDirty() {
    let isDirty = false;

    $(this.form)
      .find(':input:not(:button):not([type=hidden])')
      .each(function () {
        if (
          (this.type === 'text' ||
            this.type === 'textarea' ||
            this.type === 'hidden') &&
          this.defaultValue !== this.value
        ) {
          isDirty = true;
          return false; // returning false from this callback will break the each looping
        } else {
          if (
            (this.type === 'radio' || this.type === 'checkbox') &&
            this.defaultChecked !== this.checked
          ) {
            isDirty = true;
            return false;
          } else {
            if (this.type === 'select-one' || this.type === 'select-multiple') {
              for (let x = 0; x < this.length; x++) {
                if (
                  this.options[x].selected !== this.options[x].defaultSelected
                ) {
                  isDirty = true;
                  return false;
                }
              }
            }
          }
        }
      });

    return isDirty;
  }
}
