// app/javascript/controllers/nested_form_controller.js
import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  static targets = ['template', 'target'];

  connect() {
    super.connect();
    this.formType = this.element.dataset.nestedFormType;
    this.updateFieldsVisibility();
  }

  add(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );
    this.targetTarget.insertAdjacentHTML('beforeend', content);

    if (this.formType === 'ai-assessment') {
      this.initializeNewAnswer(event);
    }
    this.updateFieldsVisibility();
  }

  remove(event) {
    event.preventDefault();
    const wrapper = event.target.closest('.nested-fields');

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.style.display = 'none';
      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = '1';
    }
  }

  initializeNewAnswer(event) {
    const newField = this.targetTarget.lastElementChild;
    const nextQuestionIdField = newField.querySelector(
      'select[name*="next_question_id"]',
    );
    const resultField = newField.querySelector('select[name*="result"]');

    if (nextQuestionIdField) {
      nextQuestionIdField.addEventListener(
        'change',
        this.handleFieldChange.bind(this),
      );
    }
    if (resultField) {
      resultField.addEventListener('change', this.handleFieldChange.bind(this));
    }
  }

  handleFieldChange(event) {
    const field = event.target;
    const wrapper = field.closest('.nested-fields');
    const nextQuestionIdField = wrapper.querySelector(
      'select[name*="next_question_id"]',
    );
    const resultField = wrapper.querySelector('select[name*="result"]');

    if (field === nextQuestionIdField && field.value) {
      resultField.value = '';
      resultField.disabled = true;
    } else if (field === resultField && field.value) {
      nextQuestionIdField.value = '';
      nextQuestionIdField.disabled = true;
    } else {
      nextQuestionIdField.disabled = false;
      resultField.disabled = false;
    }
  }

  updateFieldsVisibility() {
    const answerFields = this.element.querySelectorAll('.nested-fields');
    answerFields.forEach((field) => {
      const nextQuestionSelect = field.querySelector(
        'select[name*="next_question_id"]',
      );
      const resultSelect = field.querySelector('select[name*="result"]');

      if (nextQuestionSelect && resultSelect) {
        if (nextQuestionSelect.value) {
          resultSelect.value = '';
          resultSelect.disabled = true;
        } else if (resultSelect.value) {
          nextQuestionSelect.value = '';
          nextQuestionSelect.disabled = true;
        } else {
          nextQuestionSelect.disabled = false;
          resultSelect.disabled = false;
        }
      }
    });
  }

  validateNextQuestionId(event) {
    const input = event.target;
    const value = parseInt(input.value);
    if (isNaN(value) || value <= 0) {
      input.setCustomValidity('Please enter a valid question ID');
    } else {
      input.setCustomValidity('');
    }
    input.reportValidity();
  }
}
