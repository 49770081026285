export function highlightSection(inputString, substring) {
  // Find the position of the substring in the inputString
  const index = String(inputString)
    .toLowerCase()
    .indexOf(substring.toLowerCase());

  // If the substring is found, wrap it with <b> tags
  if (index !== -1) {
    const before = inputString.substring(0, index);
    const middle = inputString.substring(index, index + substring.length);
    const after = inputString.substring(index + substring.length);

    return `${before}<b>${middle}</b>${after}`;
  }

  // If substring not found, return the original string
  return inputString;
}

export function splitByWhitespace(str) {
  return str.split(/(\s+)/).filter((e) => e.trim().length > 0);
}

export function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
}
