import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static values = {
    message: String,
    title: String,
    button: String,
    onSubmit: { type: Boolean, default: false },
    formId: String,
  };

  connect() {
    this.dialog = document.querySelector('dialog#alert-modal');

    if (this.onSubmitValue) {
      // Check if the formIdValue is set
      if (!this.hasFormIdValue) return;

      console.log(document.getElementById(this.formIdValue));

      // Listen for the form submit event and show alert message on successful form submittion
      document
        .getElementById(this.formIdValue)
        .addEventListener(
          'turbo:submit-end',
          this.showAlertOnSubmit.bind(this),
        );
    } else {
      // Listen for the click event and show alert message
      this.element.addEventListener('click', this.showAlert.bind(this));
    }
  }

  showAlertOnSubmit(event) {
    if (event.detail.success) {
      setTimeout(() => {
        this.showAlert();
      }, 500);
    }
  }

  showAlert() {
    this.dialog.showModal();
    this.open(this.dialog);
  }

  open() {
    if (this.dialog.open) {
      if (this.titleValue) {
        $('#alert-modal-title').text(this.titleValue);
      }

      if (this.messageValue) {
        $('#alert-modal-message').html(this.messageValue);
      }

      if (this.buttonValue) {
        $('#alert-modal-button').text(this.buttonValue);
      }
    }
  }
}
