import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['expandable'];

  toggleExpandable(e) {
    if (e.currentTarget.checked) {
      $(this.expandableTarget).show();
    } else {
      $(this.expandableTarget).hide();
    }
  }
}
