// app/javascript/controllers/assessment_answer_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'nextQuestion',
    'result',
    'recommendedAssets',
    'recommendedAssetTemplate',
    'customAnswer',
  ];

  connect() {
    this.updateSelectionState();
  }

  addNewRecommendedAsset() {
    const content = this.recommendedAssetTemplateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );
    this.recommendedAssetsTarget.insertAdjacentHTML('beforeend', content);
    this.recommendedAssetsTarget.classList.remove('hidden');

    // Scroll to the newly added asset
    const newAsset = this.recommendedAssetsTarget.lastElementChild;
    newAsset.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  removeRecommendedAsset(event) {
    event.preventDefault();
    const assetField = event.target.closest('.recommended-asset-fields');
    assetField.remove();

    // If there are no more recommended assets, hide the container
    if (this.recommendedAssetsTarget.children.length === 0) {
      this.recommendedAssetsTarget.classList.add('hidden');
    }
  }

  ensurePromptOption(selectElement) {
    const promptOption = selectElement.querySelector('option[value=""]');
    if (!promptOption) {
      const newPromptOption = document.createElement('option');
      newPromptOption.value = '';
      newPromptOption.text =
        selectElement === this.nextQuestionTarget
          ? 'Select next question'
          : 'Select result';
      selectElement.insertBefore(newPromptOption, selectElement.firstChild);
    }
  }

  handleSelection(event) {
    this.updateSelectionState();
  }

  toggleCustomAnswer(event) {
    const selectedValue = event.target.value;
    if (selectedValue === '') {
      this.customAnswerTarget.classList.remove('hidden');
    } else {
      this.customAnswerTarget.classList.add('hidden');
    }
  }

  toggleRecommendedAsset(event) {
    event.preventDefault();

    if (
      this.hasRecommendedAssetsTarget &&
      this.recommendedAssetsTarget.children.length > 0
    ) {
      // If there's already a recommended asset, toggle its visibility
      this.recommendedAssetsTarget.classList.toggle('hidden');
    } else {
      // If there's no recommended asset, add a new one
      this.addNewRecommendedAsset();
    }
  }

  updateSelectionState() {
    const nextQuestionSelected = this.nextQuestionTarget.value !== '';
    const resultSelected = this.resultTarget.value !== '';

    if (nextQuestionSelected) {
      this.resultTarget.disabled = true;
      this.resultTarget.classList.add('bg-gray-100', 'cursor-not-allowed');
      this.nextQuestionTarget.classList.remove(
        'bg-gray-100',
        'cursor-not-allowed',
      );
    } else if (resultSelected) {
      this.nextQuestionTarget.disabled = true;
      this.nextQuestionTarget.classList.add(
        'bg-gray-100',
        'cursor-not-allowed',
      );
      this.resultTarget.classList.remove('bg-gray-100', 'cursor-not-allowed');
    } else {
      this.nextQuestionTarget.disabled = false;
      this.resultTarget.disabled = false;
      this.nextQuestionTarget.classList.remove(
        'bg-gray-100',
        'cursor-not-allowed',
      );
      this.resultTarget.classList.remove('bg-gray-100', 'cursor-not-allowed');
    }
  }

  get recommendedAssetTemplate() {
    return this.recommendedAssetsTarget.querySelector('template').innerHTML;
  }
}
