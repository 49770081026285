// app/javascript/controllers/colour_set_picker_controller.js
import { Controller } from '@hotwired/stimulus';
import $ from "jquery";

export default class extends Controller {
  static targets = ['fieldTemplate', "addButton"];

  connect() {
    // You can use this lifecycle method to initialize if needed
  }

  addField(event) {
    event.preventDefault();
      
    // Clone the input wrapper
    const newInputWrapper = $(this.fieldTemplateTarget).clone();
    newInputWrapper.removeClass('hidden');
    newInputWrapper.removeAttr('data-dynamic-field-set-target');
    newInputWrapper.addClass('dynamic-input-wrapper');

    // Clear the value of the cloned input field
    newInputWrapper.find('input').val('');
    newInputWrapper.find('input').prop('disabled', false);

 
    // Append the cloned input before the add button
    $(this.addButtonTarget).before(newInputWrapper);
  }

  removeField(event) {
    event.preventDefault();

    // Remove the input wrapper when the remove button is clicked
    $(event.currentTarget).closest('.dynamic-input-wrapper').remove();
  }
}
