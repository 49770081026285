import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['options', 'title'];

  static values = {
    ignoreLabelUpdate: { type: Boolean, default: false },
  };

  connect() {
    this.initialText = $(this.titleTarget).text();

    if (!this.ignoreLabelUpdateValue) {
      $(this.optionsTarget)
        .find('input')
        .on('change', this.updateSelectorTitle.bind(this));
    }

    this.form = $(this.element)
      .closest('form')
      .on('reset', this.resetTitle.bind(this));
  }

  resetTitle() {
    $(this.titleTarget).text(this.initialText);
  }

  updateSelectorTitle() {
    const selected = $(this.optionsTarget).find('input[type="radio"]:checked');

    if (selected.length)
      $(this.titleTarget).text($(selected).next('label').text());
    $(this.titleTarget).addClass('updated');

    $(this.optionsTarget).delay(5).slideFadeToggle(100);

    const icon = $(this.titleTarget).next('i');

    if (icon) {
      $(icon).toggleClass('rotate-180');
    }
  }
}
