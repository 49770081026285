import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['price', 'rewards', 'total', 'reward', 'permission'];

  connect() {
    this.startPriceRefresh();
  }

  setValue(price) {
    // tokens held * price
    const totalValue = (
      price * parseFloat(this.rewardsTarget.textContent)
    ).toFixed(2);
    this.totalTarget.textContent = totalValue;
  }

  disconnect() {
    this.stopPriceRefresh();
  }

  // Update token values every 2 seconds
  startPriceRefresh() {
    this.refreshInterval = setInterval(() => {
      this.updateTokenValues();
    }, 2000);
  }

  stopPriceRefresh() {
    clearInterval(this.refreshInterval);
  }

  // Function to update token values
  updateTokenValues() {
    const variation = 0.000001; // Variation range for token values

    this.rewardTargets.forEach((element) => {
      const oldValue = parseFloat(element.textContent);
      const newValue = this.getRandomTokenValue(oldValue, variation);
      element.textContent = newValue;
      // Optionally, change text color based on value
      element.style.color = newValue > oldValue ? '#34d399' : '#f87171';
    });
  }

  // Function to generate a random token value within a range
  getRandomTokenValue(baseValue, variation) {
    const randomFactor = Math.random() * variation * 2 - variation;
    return (baseValue + randomFactor).toFixed(8);
  }

  toggle() {
    const permissionRow = this.permissionTarget;
    const icon = permissionRow.querySelector('i');

    if (permissionRow.classList.contains('granted')) {
      permissionRow.classList.remove('granted');
      permissionRow.classList.add('denied');
      icon.classList.remove('fa-user-check');
      icon.classList.add('fa-user-times');
    } else {
      permissionRow.classList.remove('denied');
      permissionRow.classList.add('granted');
      icon.classList.remove('fa-user-times');
      icon.classList.add('fa-user-check');
    }
  }
}
