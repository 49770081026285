import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

import { buildURL, turboFetchRequest } from '../src/helpers/requests';

const ROOT_PATH = '/analytics';
const PATHES = {
  remove_dimensions: 'remove_dimensions',
  dimensions: 'dimensions',
  add_dimensions: 'add_dimensions',
  variables: 'variables',
};

export default class extends Controller {
  static targets = ['dimensions', 'variables', 'dimension', 'filterDimension'];

  static values = {
    url: String,
    metric: String,
  };

  connect() {}

  removeFilter(e) {
    const selectedOption = e.target.value;
    const selectedFilters = [];
    const primaryDimention = $(this.dimensionsTargets).filter(':checked');

    $(this.filterDimensionTargets)
      .filter(':checked')
      .each(function () {
        selectedFilters.push($(this).val());
      });

    const params = {
      target: `dimension_selector_${selectedOption}`,
      primary_dimention: $(primaryDimention).val(),
      model: $(primaryDimention).data('dimension-model'),
      selected_filter_dimentions: selectedFilters,
    };

    const url = buildURL(PATHES.remove_dimensions, params, ROOT_PATH);

    turboFetchRequest(
      url,
      'Something went wrong - failed to remove filter dimension.',
    );
  }

  addFilter(event) {
    const eventTarget = event.currentTarget;
    const selectedOption = eventTarget.value;

    const params = {
      target: eventTarget.dataset.target,
      model: eventTarget.dataset.variable,
      scope: selectedOption,
    };

    const url = buildURL(PATHES.add_dimensions, params, ROOT_PATH);

    const callback = () => {
      const primaryVariableDimensionTarget =
        event.target.dataset.primaryVariableDimensionTarget;
      const primaryDropdown = document.querySelector(
        `#${primaryVariableDimensionTarget}`,
      );

      // Also attempt to find and remove the new filter option from the MAIN Dimension dropdown

      if (primaryDropdown) {
        const primarySelectedOption = primaryDropdown.querySelector(
          `input[type="radio"][value="${selectedOption}"]`,
        );
        this.remove_dimension_from_list(primarySelectedOption);
      }
    };

    turboFetchRequest(
      url,
      'Something went wrong - failed to fetch new dimension data.',
      callback,
    );
  }

  toggleFilter(event) {
    if (event.currentTarget.checked) {
      this.addFilter(event);
    } else {
      this.removeFilter(event);
    }
  }

  dimension_change(event) {
    const selectedValue = event.currentTarget.value;

    this.refresh_dimension_values(
      selectedValue,
      event.target.dataset.dimensionModel,
    );
  }

  refresh_dimension_values(selected, model) {
    const selectedValue = selected || this.dimensionsTarget.value;

    const selectedFilters = [];

    $(this.filterDimensionTargets)
      .filter(':checked')
      .each(function () {
        selectedFilters.push($(this).val());
      });

    const params = {
      target: this.dimensionTarget.id,
      model,
      scope: selectedValue,
    };

    const url = buildURL(PATHES.dimensions, params, ROOT_PATH);

    // Request updates the filter dropdown with new available dimensions
    // so we need to make all selected filter-dimension's checkboxes checked in a new list
    const callback = () => {
      // uses setTimeout to skip one tick and apply changes only after Turbo.renderStreamMessage(html) is done
      setTimeout(() => {
        selectedFilters.forEach((filter) => {
          $(this.filterDimensionTargets)
            .filter(`[value=${filter}]`)
            .prop('checked', true);
        });
      }, 10);
    };

    turboFetchRequest(
      url,
      'Something went wrong - failed to fetch new dimension data.',
      callback,
    );
  }

  remove_dimension_from_list(target) {
    const dropdown = target.closest('li');

    // Remove the selected input from the available filters dropdown
    if (dropdown && target.tagName.toLowerCase() === 'input') {
      dropdown.remove();
    } else {
      // No matching elements found
      console.warn(
        'WARNING - No matching dimension found in the targeted dropdown.',
      );
    }
  }

  variableChange(event) {
    const params = {
      variable: event.target.value,
      turbo_target: event.target.dataset.turboTarget,
    };

    const url = buildURL(PATHES.variables, params, ROOT_PATH);

    turboFetchRequest(
      url,
      'Something went wrong - failed to setup page for new Variable.',
    );
  }
}
