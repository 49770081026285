import BaseChartsController from './base_charts_controller.js';

export default class extends BaseChartsController {
  static targets = ['container', 'tooltip', 'legend'];

  static values = {
    yAxis: { type: Array, default: [] },
    xAxis: { type: Array, default: [] },
    options: { type: Object, default: {} },
    traces: { type: Array, default: [] },
    colorSet: { type: Array, default: [] },
  };

  connect() {
    super.connect();

    const data = this.chartData();

    const layout = {
      ...this.defaultLayoutConfig,
      xaxis: {
        ...this.defaultLayoutConfig.xaxis,
        labelalias: this.labelAlias,
      },
      showline: true,
      bargap: this.isDefaultOrientation ? 0.05 : 0.2,
      margin: {
        t: this.isDefaultOrientation ? 34 : 15,
        b: 0,
        r: 0,
        l: 0,
        pad: 8,
      },
    };

    if (!this.yAxisValue.length || !this.xAxisValue.length) {
      this.drawEmptyResult(layout, data);

      return;
    }

    this.drawChart(data, layout);
  }

  chartData() {
    if (this.hasTraces) {
      return this.tracesValue.map((trace, index) => {
        const yAxis = this.yAxisValue.map((i) => i[trace]);

        return {
          name: this.geTraceName(trace),
          type: 'bar',
          initialName: trace,
          orientation: this.orientation,
          visible: true,
          hoverinfo: 'none',
          marker: {
            color: this.colorSetValue[index] || null,
          },
          x: this.isDefaultOrientation ? this.xAxisValue : yAxis,
          y: this.isDefaultOrientation ? yAxis : this.xAxisValue,
        };
      });
    } else {
      return [
        {
          type: 'bar',
          x: (this.isDefaultOrientation
            ? this.xAxisValue
            : this.yAxisValue
          ).reverse(),
          y: (this.isDefaultOrientation
            ? this.yAxisValue
            : this.xAxisValue
          ).reverse(),
          orientation: this.orientation,
          hoverinfo: 'none',
          width: 0.1,
          marker: {
            color: this.colorSetValue,
          },
        },
      ];
    }
  }
}
