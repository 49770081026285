import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['component'];

  connect() {
    this.initializeDragAndDrop();
  }

  initializeDragAndDrop() {
    document.querySelectorAll('#components-menu li').forEach((item) => {
      item.setAttribute('draggable', true);
      item.addEventListener('dragstart', this.handleDragStart);
    });

    const componentsArea = document.getElementById('components-area');
    componentsArea.addEventListener('dragover', this.handleDragOver);
    componentsArea.addEventListener('drop', this.handleDrop.bind(this));
  }

  handleDragStart(e) {
    e.dataTransfer.setData('text/plain', e.target.dataset.componentName);
  }

  handleDragOver(e) {
    e.preventDefault();
  }

  handleDrop(e) {
    e.preventDefault();
    const componentName = e.dataTransfer.getData('text/plain');
    this.addComponentToDashboard(componentName);
  }

  addComponentToDashboard(componentName) {
    const componentClass = this.getComponentClass(componentName);
    const componentElement = document.createElement('div');
    componentElement.innerHTML = componentClass.render().outerHTML;
    document.getElementById('components-area').appendChild(componentElement);
  }

  getComponentClass(componentName) {
    switch (componentName) {
      case 'Header':
        return new Header();
      case 'Footer':
        return new Footer();
      case 'Widget':
        return new Widget();
      default:
        return null;
    }
  }
}

class Header {
  render() {
    const element = document.createElement('div');
    element.innerHTML = '<%= render HeaderComponent.new %>';
    return element;
  }
}

class Footer {
  render() {
    const element = document.createElement('div');
    element.innerHTML = '<%= render FooterComponent.new %>';
    return element;
  }
}

class Widget {
  render() {
    const element = document.createElement('div');
    element.innerHTML = '<%= render WidgetComponent.new %>';
    return element;
  }
}
