import { Controller } from '@hotwired/stimulus';
import { PULSE_API_ROOT } from './constants';

export default class extends Controller {
  static targets = ['fieldSelect', 'fieldInput'];

  connect() {
    this.fetchFieldDefinitions();
  }

  fetchFieldDefinitions() {
    fetch(`${PULSE_API_ROOT}/admin/configurations.json`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.definitions = data;
        this.populateDropdown();
      });
  }

  // [ name: , value: , type: }
  populateDropdown() {
    this.definitions.forEach((definition) => {
      const option = document.createElement('option');
      option.textContent = definition.name;
      option.value = definition.name;
      this.fieldSelectTarget.appendChild(option);
    });
  }

  fieldSelectChanged() {
    const selectedDefinition = this.definitions.find(
      (d) => d.name === this.fieldSelectTarget.value,
    );

    if (selectedDefinition) {
      this.fieldInputTarget.type = selectedDefinition.type;
      this.fieldInputTarget.value = selectedDefinition.value;
    } else {
      console.error(
        'No matching definition found for selected value:',
        this.fieldSelectTarget.value,
      );
    }
  }
}
