import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['container', 'entries', 'pagination'];

  connect() {
    const observer = new IntersectionObserver((entries) =>
      this.handleIntersect(entries),
    );

    observer.observe(this.paginationTarget);
  }

  handleIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  loadMore() {
    const link = this.paginationTarget.querySelector("a[rel='next']");

    if (!link || !link.href) {
      return;
    }

    fetch(link.href, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
