// app/javascript/controllers/recommended_asset_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['linkField', 'filesField'];

  toggleFields(event) {
    const assetType = event.target.value;
    this.linkFieldTarget.classList.toggle('hidden', assetType !== 'link');
    this.filesFieldTarget.classList.toggle('hidden', assetType !== 'files');
  }

  removeFile(event) {
    event.preventDefault();
    const fileId = event.currentTarget.dataset.fileId;
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = `${this.element.querySelector('input[type=file]').name.replace('[]', '')}[${fileId}]`;
    input.value = '1';
    this.element.appendChild(input);
    event.currentTarget.closest('li').remove();
  }
}
