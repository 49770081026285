import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { getMetaValue } from './constants';

import consumer from '../channels/consumer';

export default class extends Controller {
  static values = {
    availableSubscriptions: { type: Array, default: [] },
  };

  connect() {
    if (this.availableSubscriptionsValue.length) {
      this.checkActiveSubscriptions();
    }
  }

  checkActiveSubscriptions() {
    this.availableSubscriptionsValue.forEach((subscription) => {
      if (!this.isSubscribed(subscription.name)) {
        this.subscribeChannelToRecieveNotification(
          subscription.name,
          subscription.notify_url,
        );
      }
    });
  }

  isSubscribed(subscriptionName) {
    const subscriptionsList = consumer.subscriptions.subscriptions.filter((i) =>
      i.identifier.includes(subscriptionName),
    );

    return subscriptionsList.length > 0;
  }

  subscribeChannelToRecieveNotification(channelName, notifyUrl) {
    const self = this;

    consumer.subscriptions.create(channelName, {
      connected() {
        console.log(channelName, '___connected');
      },

      disconnected() {
        console.log(channelName, '__disconnected');

        self.checkActiveSubscriptions();
      },

      // TODO: probably need to make it more generic
      received(data) {
        console.log('data - ', data);
        if (data.status && data.status === 'error') {
          fetch('flash_messages', {
            method: 'POST',
            headers: {
              'X-CSRF-Token': getMetaValue('csrf-token'),
              'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
            body: JSON.stringify({
              status: 'error',
              message: data.message || 'Something went wrong',
            }),
          })
            .then((response) => response.text())
            .then((html) => Turbo.renderStreamMessage(html));
        }
        // const jobId = data[0];
        // const userId = data[1];
        // const filename = data[2];

        // fetch(
        //   `${notifyUrl}?job_id=${jobId}&user_id=${userId}&filename=${filename}`,
        // );
      },
    });
  }
}
