import BaseChartsController from './base_charts_controller.js';

export default class extends BaseChartsController {
  static targets = ['container'];

  static values = {
    data: { type: Object, default: {} },
    labels: { type: Object, default: {} },
    colorSet: { type: Array, default: [] },
  };

  connect() {
    const traces = Object.keys(this.dataValue);

    const data = traces.map((trace, i) => {
      return {
        x: [this.dataValue[trace]],
        text: [this.dataValue[trace]],
        name: trace,
        orientation: 'h',
        marker: {
          color: this.colorSetValue[i] || this.defaultColorSet[i],
          width: 1,
        },
        type: 'bar',
        hoverinfo: 'none',
      };
    });

    const layout = {
      ...this.defaultLayoutConfig,
      xaxis: {
        showticklabels: false,
        showline: false,
        showgrid: false,
        showdividers: false,
        zeroline: false,
        fixedrange: true,
      },
      yaxis: {
        showticklabels: false,
        showline: false,
        showgrid: false,
        showdividers: false,
        fixedrange: true,
      },
      margin: { t: 0, b: 0, r: 0, l: 0 },
      showlegend: false,
    };

    this.drawChart(data, layout, false);
  }
}
