export default class NewCardValidationHelper {
  validationRules(errorMessages) {
    return [
      {
        type: 'field',
        id: '#card_title',
        rules: [
          {
            rule: 'required',
          },
        ],
      },
      {
        type: 'field',
        id: '#card_model',
        rules: [
          {
            rule: 'required',
          },
        ],
      },
      {
        type: 'group',
        id: '#card_visualisations_group',
      },
    ];
  }
}
