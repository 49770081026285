import { Controller } from '@hotwired/stimulus';
// Mock prototype to Toggle Permissions
export default class extends Controller {
  static targets = ['icon', 'label', 'trigger', 'process', 'grantIcon'];

  connect() {
    this.toggleIcons();
  }

  toggle() {
    this.toggleIcons();
  }

  evaluateProcessCheckbox() {
    const processElement = this.processTarget;
    if (processElement) {
      if (this.triggerTarget.checked) {
        processElement.removeAttribute('disabled');
        processElement.classList.remove('red');
        processElement.classList.add('bg-color-brand-premium-800');
      } else {
        processElement.setAttribute('disabled', '');
        processElement.classList.remove('bg-color-brand-premium-800');
        processElement.classList.add('red');
      }
    }
  }

  toggleIcons() {
    const icon = this.grantIconTarget;

    // PUBLISHED
    if (icon.classList.contains('fa-user-check')) {
      icon.classList.remove('fa-user-check');
      icon.classList.add('fa-user-times');
      icon.classList.remove('text-amber-950');
      icon.classList.add('text-color-brand-premium-800');

      if (this.hasProcessTarget) {
        this.processTarget.removeAttribute('disabled');
        this.processTarget.classList.remove('bg-amber-950');
        this.processTarget.classList.add('bg-color-brand-premium-800');
      }

      this.iconTarget.className =
        'fa-solid fa-circle-check fa-fw text-highlighted-hex';
      this.labelTarget.textContent = 'Published';
      this.labelTarget.classList.remove('text-amber-950');
      this.labelTarget.classList.add('text-color-brand-premium-800');
    } else {
      // REVOKED
      icon.classList.remove('fa-user-times');
      icon.classList.add('fa-user-check');
      icon.classList.remove('text-color-brand-premium-800');
      icon.classList.add('text-amber-950');

      if (this.hasProcessTarget) {
        this.processTarget.setAttribute('disabled', 'true');
        this.processTarget.classList.remove('bg-color-brand-premium-800');
        this.processTarget.classList.add('bg-amber-950');
      }

      this.iconTarget.className = '';
      this.labelTarget.textContent = 'Revoked';
      this.labelTarget.classList.remove('text-color-brand-premium-800');
      this.labelTarget.classList.add('text-amber-950');
    }
  }
}
