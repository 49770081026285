import TimeSeriesChartsController from './time_series_charts_controller.js';

// Required data for stacked area chart

// title - chart title

// traces (for stacked bar chart) - array

// xAxis - array of string/number/date to be displayed on the x-axis
// e.g. ["Product1", "Product2", "Product3"]

// yAxis - array of values for y axis or array of Hashes if traces provided
// e.g. ["Location1", "Location2"] or
// [{ trace1: value, trace2: value }, { trace1: value, trace2: value }]

export default class extends TimeSeriesChartsController {
  static targets = ['container', 'tooltip', 'legend'];

  static values = {
    title: { type: String, default: '' },
    yAxis: { type: Array, default: [] },
    xAxis: { type: Array, default: [] },
    traces: { type: Array, default: [] },
    options: { type: Object, default: {} },
    colorSet: { type: Array, default: [] },
    dateRangeTick: { type: String, default: '1 month' },
    timePeriod: { type: String, default: '12M' },
  };

  connect() {
    super.connect();

    const traceTemplate = {
      x: this.xAxis,
      line: {
        width: 6,
      },
      visible: true,
      marker: {
        color: this.colorSetValue[0] || this.defaultColorSet[0],
        size: 1,
      },
      stackgroup:
        this.tracesValue.length > 1 && this.isRelativeMode ? 'one' : '',
      groupnorm: this.isRelativeMode ? 'percent' : '',
      hoverinfo: 'none',
      mode: 'lines+markers',
    };

    let data = [];

    if (this.tracesValue.length > 0) {
      data = this.tracesValue.map((trace, index) => {
        return {
          ...traceTemplate,
          y: this.yAxis.map((i) => i[trace]),
          initialName: trace,
          name: this.geTraceName(trace),
          marker: {
            color: this.colorSetValue[index] || null,
            size: 1,
          },
          fill: 'tonexty',
          type: 'scatter',
        };
      });
    } else {
      data = [
        {
          ...traceTemplate,
          y: this.yAxis,
          fill: 'tonexty',
          type: 'scatter',
        },
      ];
    }

    if (!this.yAxisValue.length || !this.xAxisValue.length) {
      this.drawEmptyResult(this.defaultTimeSeriesLayoutConfig, data);

      return;
    }

    this.drawChart(data, this.defaultTimeSeriesLayoutConfig);
  }
}
