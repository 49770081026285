import FormActionsButtonController from './form_actions_button_controller';
import debounce from 'lodash.debounce';
import $ from 'jquery';

export default class extends FormActionsButtonController {
  connect() {
    super.connect();
    $(this.form).on('reset', this.blankForm.bind(this));
  }

  blankForm = debounce(this.doBlankForm, 0);

  toggleButton() {
    $(this.element).prop('disabled', !this.isFormDirty && this.isFormEmpty);
  }

  doBlankForm() {
    $(this.form)
      .find(':input:not(:button):not([type=hidden])')
      .each(function () {
        if (
          (this.type === 'text' || this.type === 'textarea') &&
          this.value !== ''
        ) {
          this.value = '';
        } else {
          if (
            (this.type === 'radio' || this.type === 'checkbox') &&
            this.checked
          ) {
            this.click();
          } else {
            if (this.type === 'select-one' || this.type === 'select-multiple') {
              for (let x = 0; x < this.length; x++) {
                if (this.options[x].selected) {
                  this.click();
                }
              }
            }
          }
        }
      });

    $(this.element).prop('disabled', true);
  }

  get isFormEmpty() {
    let isEmpty = true;

    $(this.form)
      .find(':input:not(:button):not([type=hidden])')
      .each(function () {
        if (
          (this.type === 'text' ||
            this.type === 'textarea' ||
            this.type === 'hidden') &&
          this.value !== ''
        ) {
          isEmpty = false;
          return false; // returning false from this callback will break the each looping
        } else {
          if (
            (this.type === 'radio' || this.type === 'checkbox') &&
            this.checked
          ) {
            isEmpty = false;
            return false;
          } else {
            if (this.type === 'select-one' || this.type === 'select-multiple') {
              for (let x = 0; x < this.length; x++) {
                if (this.options[x].selected) {
                  isEmpty = false;
                  return false;
                }
              }
            }
          }
        }
      });

    return isEmpty;
  }
}
