import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['expandable', 'option'];

  connect() {
    console.log(this.optionTargets);

    $(this.optionTargets).on('change', this.radioClick.bind(this));
  }

  radioClick(e) {
    const value = e.target.value;

    $(this.expandableTargets).addClass('hidden');
    $(this.expandableTargets).find('input, select').prop('disabled', true);

    const activeExpandable = $(this.expandableTargets)
      .filter(function () {
        return $(this).data('option') === value;
      })
      .first();

    $(activeExpandable).removeClass('hidden');
    $(activeExpandable).find('input, select').prop('disabled', false);
  }
}
