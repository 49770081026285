import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static values = {
    url: String,
    requestMethod: { type: String, default: 'PATCH' },
    requestBody: { type: Object, default: {} },
  };

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      animation: 150,
    });
  }

  onEnd(event) {
    const item = event.item;

    const oldIndex = event.oldIndex;
    const newIndex = event.newIndex;

    if (oldIndex === newIndex) {
      return;
    }

    fetch(`${this.urlValue}/${item.dataset.id}`, {
      method: this.requestMethodValue,
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.getMetaValue('csrf-token'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        ...this.requestBodyValue,
        position: newIndex,
      }),
    });
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }
}
