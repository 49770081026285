import BaseChartsController from './base_charts_controller.js';
import * as d3 from 'd3';

const SIZE = {
  small: {
    width: 92,
    height: 92,
    innerRadius: 38,
  },
  big: {
    width: 550,
    height: 550,
    innerRadius: 250,
  },
};

const EMPTY_DATA = [{ key: '', colour: '#e8e8e8', value: 1 }];

export default class extends BaseChartsController {
  static targets = ['container', 'tooltip'];

  static values = {
    data: { type: Array, default: [] },
    size: { type: String, default: 'small' },
  };

  get isEmpty() {
    return this.dataValue.every((v) => v.value === 0);
  }

  connect() {
    const size = SIZE[this.sizeValue] || SIZE.small;
    const width = size.width;
    const height = size.height;
    const innerRadius = size.innerRadius;

    const outerRadius = width / 2;
    const pieChart = d3
      .pie()
      .value((d) => {
        return d.value;
      })
      .sort(null);

    const arc = d3
      .arc()
      .outerRadius(outerRadius)
      .innerRadius(innerRadius)
      .cornerRadius(3)
      .padAngle(0.015);

    if (!d3.select(this.containerTarget).select('svg').empty()) {
      return;
    }

    const svg = d3
      .select(this.containerTarget)
      .append('svg')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    const path = svg
      .selectAll('path')
      .data(pieChart(this.isEmpty ? EMPTY_DATA : this.dataValue))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d) {
        return d.data.colour;
      });

    path
      .transition()
      .duration(1000)
      .attrTween('d', function (d) {
        const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
        return function (t) {
          return arc(interpolate(t));
        };
      });
  }
}
