import { Controller } from '@hotwired/stimulus';
import { PULSE_API_ROOT } from '../constants';

export default class extends Controller {
  update() {
    const grantorId = this.element.value;

    fetch(`${PULSE_API_ROOT}/grantors/${grantorId}/data_subject_rights`)
      .then((response) => response.json())
      .then((data) => {
        const dataSubjectRightsOptions = data.map((dataSubjectRight) => {
          return `<option value="${dataSubjectRight.id}">${dataSubjectRight.name}</option>`;
        });

        document.getElementById('request_type_id').innerHTML =
          dataSubjectRightsOptions.join('');
      });
  }
}
