// TODO: How can we import this from the pulse_front engine rather than copying here ???

import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

const HIDE_FLASH_MESSAGE_DELAY = 6000;
const ERROR_HIDE_FLASH_MESSAGE_DELAY = 8000;

export default class extends Controller {
  static targets = ['progressBar'];
  // Durations are given in milliseconds- higher values indicate slower animations

  static values = {
    duration: { type: Number, default: 5000 },
  };

  connect() {
    // Anytime the pulse_pref_cent_messages turbo frame is rendered
    // we can scroll it into view
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        if (this.hasProgressBarTarget) {
          $(this.progressBarTarget).css({ transform: 'translateX(-100%)' });
        }
      });
    });

    $('.notice:visible')
      .delay(HIDE_FLASH_MESSAGE_DELAY)
      .fadeOut(300, function () {
        $(this).remove();
      });
    // Adjusts the opacity of the matched element down to 0

    $('.alert:visible')
      .delay(ERROR_HIDE_FLASH_MESSAGE_DELAY)
      .fadeOut(300, function () {
        $(this).remove();
      });
  }

  clearMessage(e) {
    $(e.currentTarget).parent().remove();
  }
}
