import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const firstItem = document.querySelector('.accordion-panel');
    if (firstItem) {
      this.toggleAccordion(firstItem);
    }
  }

  onActivateItem = (e) => {
    const activeItem = e.target.closest('.accordion-panel');
    if (!activeItem) return;

    this.toggleAccordion(activeItem);
  };

  toggleAccordion = (activeItem) => {
    const isClosed = activeItem.querySelector('button').getAttribute('aria-expanded') === 'false';

    // close all panels anyway
    const buttons = activeItem.parentElement.querySelectorAll('.accordion-panel > .heading > button') || [];
    const contents = activeItem.parentElement.querySelectorAll('.accordion-panel > .content') || [];

    buttons.forEach((button) => {
      button.setAttribute('aria-expanded', 'false');
    });
    contents.forEach((content) => {
      content.setAttribute('aria-hidden', 'true');
    });

    // if the the panel was closed when we clicked it, open it
    if (isClosed) {
      activeItem.querySelector('button').setAttribute('aria-expanded', 'true');
      activeItem.querySelector('.content').setAttribute('aria-hidden', 'false');
    }
  };
}