// app/javascript/controllers/tree_visualization_controller.js
import { Controller } from '@hotwired/stimulus';
import { Network } from 'vis-network/standalone';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const response = await fetch('/admin/ai/assessment_questions/tree_data');
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      this.renderTree(data);
    } catch (error) {
      console.error('Error fetching tree data:', error);
    }
  }

  renderTree(data) {
    const { nodes, edges } = this.processData(data);
    const options = {
      layout: {
        hierarchical: {
          direction: 'UD',
          sortMethod: 'directed',
          levelSeparation: 200, // Increased from 150
          nodeSpacing: 250, // Increased from 200
        },
      },
      edges: {
        arrows: 'to',
        smooth: {
          type: 'cubicBezier',
          forceDirection: 'vertical',
          roundness: 0.4,
        },
        font: {
          size: 12,
          align: 'horizontal',
          background: 'white',
        },
        labelHighlightBold: false,
        color: {
          inherit: false,
        },
      },
      physics: false,
    };

    new Network(this.containerTarget, { nodes, edges }, options);
  }

  processData(questions) {
    const nodes = [];
    const edges = [];
    const nodeMap = new Map();

    questions.forEach((question) => {
      nodes.push({
        id: question.id,
        label: question.question_text,
        shape: question.is_root ? 'star' : 'box',
        color: question.is_root ? '#FFA500' : '#97C2FC',
      });
      nodeMap.set(question.id, question);
    });

    questions.forEach((question) => {
      question.answers.forEach((answer) => {
        if (answer.next_question_id) {
          edges.push({
            from: question.id,
            to: answer.next_question_id,
            label: answer.text,
            labelHighlightBold: false,
            font: { background: 'white' },
          });
        } else if (answer.result) {
          const resultId = `result_${question.id}_${answer.id}`;
          nodes.push({
            id: resultId,
            label: `Result: ${answer.result}`,
            shape: 'ellipse',
            color: '#FFA07A',
          });
          edges.push({
            from: question.id,
            to: resultId,
            label: answer.text,
            labelHighlightBold: false,
            font: { background: 'white' },
          });
        }
      });
    });

    return { nodes, edges };
  }
}
