import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['systemType', 'crmContentToHide'];

  connect() {
    this.categories.forEach((checkbox) => {
      if (checkbox.checked) {
        this.toggleFunctionsDisplay(checkbox);
      }
    });

    const systemTypeElem = document.querySelector(
      '[data-action="change->integration#displaySystemElements"]',
    );
    if (systemTypeElem) {
      systemTypeElem.dispatchEvent(new Event('change'));
    }
  }

  displaySystemElements() {
    const isCRM =
      this.systemTypeTarget.value === 'crm' ||
      this.systemTypeTarget.value === 'CRM';
    this.crmContentToHideTarget.style.display = isCRM ? 'block' : 'none';
  }

  enableFunctions(event) {
    this.toggleFunctionsDisplay(event.currentTarget);
  }

  toggleFunctionsDisplay(checkbox) {
    const functionsCheckbox = document.querySelector(
      `.functions-${checkbox.value}`,
    );
    functionsCheckbox.style.display = checkbox.checked ? 'block' : 'none';
  }

  get categories() {
    return Array.from(
      document.querySelectorAll('input[name*="integration[category_ids]"]'),
    );
  }
}
