import BaseChartsController from './base_charts_controller.js';

export default class extends BaseChartsController {
  static targets = ['container', 'legend'];

  static values = {
    yAxis: { type: Array, default: [] },
    xAxis: { type: Array, default: [] },
    options: { type: Object, default: {} },
    traces: { type: Array, default: [] },
    colorSet: { type: Array, default: [] },
  };

  connect() {
    super.connect();

    const data = [
      {
        type: 'bar',
        x: (this.yAxisValue).reverse(),
        y: (this.xAxisValue).reverse(),
        orientation: 'h',
        hoverinfo: 'none',
        width: 0.45,
        marker: {
          color: this.colorSetValue,
        },
      },
    ];

    const layout = {
      ...this.defaultLayoutConfig,
      xaxis: {
        showticklabels: false,
        showline: false,
        showgrid: false,
        showdividers: false,
        zeroline: false,
        fixedrange: true,
      },
      yaxis: {
        showticklabels: false,
        showline: false,
        showgrid: false,
        showdividers: false,
        fixedrange: true,
      },
      margin: { t: 0, b: 0, r: 0, l: 0 },
      showlegend: false,
      marker: {
        color: this.colorSetValue,
      },
    };

    if (!this.yAxisValue.length || !this.xAxisValue.length) {
      this.drawEmptyResult(layout, data);

      return;
    }

    this.drawChart(data, layout, false);
  }
}
