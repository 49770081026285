import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = [
    'option',
    'input',
    'optionsBlock',
    'selectedOptionsBlock',
    'selectedOptionTemplate',
  ];

  static values = {
    initialDisplay: { type: String, default: 'block' },
  };

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this));

    $(this.inputTarget).on('focus', () => {
      if ($(this.inputTarget).val().length > 0) {
        $(this.optionsBlockTarget).show();
      }
    });

    $(this.optionTarget).closest('form').on('reset', this.formReset.bind(this));

    const self = this;

    $(this.optionTargets)
      .filter(':checked')
      .each(function () {
        self.addSelectedTag(this);
      });
  }

  handleClickOutside(e) {
    if (!this.element.contains(e.target)) {
      $(this.optionsBlockTarget).hide();
    }
  }

  formReset() {
    $(this.selectedOptionsBlockTarget).children(':visible').remove();
  }

  onInput = debounce(this.searchAndSortList, 300);

  searchAndSortList(e) {
    const query = $(this.inputTarget).val().toLowerCase();
    const initialDisplayValue = this.initialDisplayValue;

    $(this.optionTargets)
      .filter(':not(:checked)')
      .each(function () {
        const listItem = $(this);
        const content = listItem.data('label').toLowerCase();

        // Toggle visibility based on search match
        const label = listItem.closest('label');
        label.css(
          'display',
          content.includes(query) ? initialDisplayValue : 'none',
        );
      });

    // Sort list items visually (using jQuery methods)
    $(this.optionsBlockTarget)
      .children('label')
      .sort(function (a, b) {
        const aItem = $(a);
        const bItem = $(b);

        const aLabel = aItem.find('input').data('label').toLowerCase();
        const bLabel = bItem.find('input').data('label').toLowerCase();

        const aStartsWith = aLabel.startsWith(query);
        const bStartsWith = bLabel.startsWith(query);

        if (
          (aStartsWith && !bStartsWith) ||
          (aStartsWith && bStartsWith && aLabel.localeCompare(bLabel) > 0)
        ) {
          return -1;
        }

        return 1;
      })
      .appendTo(this.optionsBlockTarget);

    $(this.optionsBlockTarget).show();
  }

  selectOption(e) {
    const option = e.currentTarget;

    if (!option.checked) return;

    this.addSelectedTag(option);

    $(this.optionsBlockTarget).hide();

    this.inputTarget.value = '';
  }

  addSelectedTag(option) {
    const selectedBlock = $(this.selectedOptionTemplateTarget).clone(true);

    $(selectedBlock).children().first().text($(option).data('label'));

    $(selectedBlock)
      .data('value', $(option).val())
      .removeClass('hidden')
      .appendTo(this.selectedOptionsBlockTarget);

    $(this.optionTargets).closest('label').css('display', 'none');
  }

  unselectOption(e) {
    const selectedOption = $(e.currentTarget).parent();

    const value = $(selectedOption).data('value');

    $(this.optionTargets)
      .filter(':checked')
      .each(function () {
        if ($(this).val() !== value) return;

        $(this).trigger('click');
      });

    $(selectedOption).remove();
    this.inputTarget.value = '';
  }
}
