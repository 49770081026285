import $ from 'jquery';

$.fn.slideFadeToggle = function (speed, easing, callback) {
  return this.animate(
    { opacity: 'toggle', height: 'toggle', padding: 'toggle' },
    speed,
    easing,
    callback,
  );
};
