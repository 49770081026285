/* // Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

import "chartkick/chart.js"
import "@rails/request.js" */

/* eslint no-console:0 */

// Rails functionality
import Rails from '@rails/ujs';
import { Turbo } from '@hotwired/turbo-rails';
import 'chartkick/chart.js';
import 'trix';
import '@rails/actiontext';

import './src/helpers/jquery';

// ActionCable Channels
import "./channels";

// Stimulus controllers
import './controllers';

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;
window.Turbo = Turbo;

require('@rails/activestorage').start();

// Start Rails UJS
Rails.start();
