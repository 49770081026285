export default class NewUserValidationHelper {
  validationRules(errorMessages) {
    return [
      {
        type: 'field',
        id: '#user_personal_data_attributes_first_name',
        rules: [
          {
            rule: 'required',
            errorMessage: errorMessages['first_name'],
          },
        ],
      },
      {
        type: 'field',
        id: '#user_personal_data_attributes_last_name',
        rules: [
          {
            rule: 'required',
            errorMessage: errorMessages['last_name'],
          },
        ],
      },
      {
        type: 'field',
        id: '#user_email',
        rules: [
          {
            rule: 'required',
            errorMessage: errorMessages['email'],
          },
          {
            rule: 'email',
          },
        ],
      },
      {
        type: 'group',
        id: '#role_group',
        errorMessage: errorMessages['role'],
      },
      {
        type: 'group',
        id: '#account_group',
        errorMessage: errorMessages['account'],
      },
    ];
  }
}
