export default class EnquiryFormValidationHelper {
  validationRules(errorMessages) {
    return [
      {
        type: 'field',
        id: '#grantor_personal_data_attributes_first_name',
        rules: [
          {
            rule: 'required',
            errorMessage: 'This field is required',
          },
        ],
      },
      {
        type: 'field',
        id: '#grantor_personal_data_attributes_last_name',
        rules: [
          {
            rule: 'required',
            errorMessage: 'This field is required',
          },
        ],
      },
      {
        type: 'field',
        id: '#grantor_personal_data_attributes_company_name',
        rules: [
          {
            rule: 'required',
            errorMessage: 'This field is required',
          },
        ],
      },
      {
        type: 'field',
        id: '#grantor_email',
        rules: [
          {
            rule: 'required',
            errorMessage: 'This field is required',
          },
          {
            rule: 'email',
          },
        ],
      },
      {
        type: 'group',
        id: '#grantor_location_group',
        rules: [
          {
            rule: 'required',
            errorMessage: 'This field is required',
          },
        ],
      },
      {
        type: 'group',
        id: '#grantor_role_group',
        rules: [
          {
            rule: 'required',
            errorMessage: 'This field is required',
          },
        ],
      },
    ];
  }
}
